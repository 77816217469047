import { LoaderWithText } from "../../../components/Loader";

const AmazonPaymentStatus: React.FC = () => {
  return (
    <>
      <LoaderWithText
        title="Your payment is processing..."
        subTitle="Please don't close this page until the process complete"
      />
    </>
  );
};

export default AmazonPaymentStatus;
