import { useEffect } from "react";
import { LoaderWithText } from "../../../components/Loader";
import qs from "qs";
import { useHistory, useLocation } from "react-router-dom";
import { useMutation } from "react-query";
import { postPaymentStatusAPI } from "../../../api/payment/amazon/postPaymentStatus";
import { objectToQueryString } from "../../../helpers";

const AmazonRedirectPage: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const queryStringParams = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const mutation = useMutation(postPaymentStatusAPI);

  useEffect(() => {
    mutation.mutate(queryStringParams);
  }, []);

  useEffect(() => {
    if (mutation.isSuccess && mutation.data?.data) {
      window.location.href = `/#/payment/amazon/status?${objectToQueryString(
        mutation.data.data
      )}`;
    }

    if (mutation.isError && mutation.error) {
      const error: any = mutation.error;
      window.location.href = `/#/payment/amazon/status?${objectToQueryString(
        error.response.data
      )}`;
    }
  }, [mutation.isSuccess, mutation.isError]);

  return (
    <LoaderWithText
      title="Your payment is processing..."
      subTitle="Please don't close this page until the process complete"
    />
  );
};

export default AmazonRedirectPage;
