import React, { useEffect, useState } from "react";
import qs from "qs";
import { FleetTracking } from "../../../components/FleetTracking";
import { useLocation } from "react-router-dom";
import { useGetTrackingNewData } from "../../../queries/fleet /tracking";

interface LatLng {
  lat: number,
  lng:number
}

export const BarqTrackingScreen: React.FC = () => {
  const location = useLocation();
  const queryStringParams = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const [courier, setCourier] = useState<LatLng>({
    lat: Number.parseFloat(`${queryStringParams.courier_lat}`),
    lng: Number.parseFloat(`${queryStringParams.courier_long}`),
  });
  const [user, setUser] = useState<LatLng>({
    lat: Number.parseFloat(`${queryStringParams.customer_lat}`),
    lng: Number.parseFloat(`${queryStringParams.customer_long}`),
  });
  const [branch, setBranch] = useState<LatLng | null>(null);
  const [loadingNewData, setLoadingNewData] = useState<boolean>(false);

  // Query
  const { data, status, refetch } = useGetTrackingNewData(
    queryStringParams.order_id as string
  );

  //
  useEffect(() => {
    const interval = setInterval(() => {
      if (!loadingNewData) {
        setLoadingNewData(true);
        refetch();
      }
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  //
  useEffect(() => {
    if (status === "success") {
      if (data?.courier_lat && data.courier_long) {
        setCourier({lat: data.courier_lat, lng: data.courier_long});
      } 
      setLoadingNewData(false);
    }
  }, [status, data]);

  return <FleetTracking courier={courier} user={user} branch={branch} />;
};
