import { useQuery } from "react-query";
import { getBarqTrackingAPI } from "../../api/fleet/barq";

export const useGetTrackingNewData = (orderId: string) => {
  return useQuery(
    ["trackingData", orderId],
    async () => {
      return await getBarqTrackingAPI(orderId).then((res) => res.data);
    },
    {
      cacheTime: 0,
      staleTime: 0,
    }
  );

};
