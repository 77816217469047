import { HashRouter as Router, Switch, Route, Link } from "react-router-dom";
import "./App.css";
import AmazonRedirectPage from "./pages/payment/amazon/RedirectPage";
import AmazonPaymentStatus from "./pages/payment/amazon/PaymentStatus";
import { QueryClient, QueryClientProvider } from "react-query";
import { BarqTrackingScreen } from "./pages/fleet/barq/Tracking";

const queryClient = new QueryClient();

function App() {
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <Router>
          <Switch>
            <Route path="/payment/amazon/return-url">
              <AmazonRedirectPage />
            </Route>
            <Route path="/payment/amazon/status">
              <AmazonPaymentStatus />
            </Route>
            <Route path="/fleet/barq/tracking">
              <BarqTrackingScreen />
            </Route>
          </Switch>
        </Router>
      </QueryClientProvider>
    </div>
  );
}

export default App;
