import LoaderSVG from "../../assets/refresh.svg";
import { RefreshCcw } from "react-feather";
import "./style.css";

interface IProps {
  title?: string;
  subTitle?: string;
}

export const LoaderWithText: React.FC<IProps> = ({ title, subTitle }) => {
  return (
    <div
      style={{
        width: "100vw",
        height: "90vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <div
        style={{
          width: "52px",
          height: "52px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          padding: "14px",
          background: "#fff4ea",
          borderRadius: "50%",
        }}
      >
        <RefreshCcw className="loading" color="#FFA351" size={25} />
      </div>
      {title && (
        <span
          style={{
            fontSize: 17,
            fontWeight: "bold",
            marginTop: "2rem",
            padding: "0 2rem",
          }}
        >
          {title}
        </span>
      )}
      {subTitle && (
        <span
          style={{
            fontSize: 17,
            color: "#8e8e93",
            marginTop: ".5rem",
            padding: "0 2rem",
          }}
        >
          {subTitle}
        </span>
      )}
    </div>
  );
};
