import { compose, withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import MarkerClusterer from "react-google-maps/lib/components/addons/MarkerClusterer";
import BranchPinIcon from "../../assets/branch-pin.svg";
import CourierPinIcon from "../../assets/courier-pin.svg";
import UserPinIcon from "../../assets/user-pin.svg";
import { useEffect, useState } from "react";

export const FleetTracking: any = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyCz-fZ85mMDuLySzhSPHhci1SXi_i9-2_E&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `100vh` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props: any) => {
  const [markers, setMarkers] = useState<
    { photo: any; lat: number; lng: number }[]
  >([]);

  useEffect(() => {
    let newMarkers = [];
    let courierRow = {
      photo: CourierPinIcon,
      lat: props["courier"].lat,
      lng: props["courier"].lng,
    };
    let userRow = {
      photo: UserPinIcon,
      lat: props["user"].lat,
      lng: props["user"].lng,
    };

    // inject courier and user markers for the map
    newMarkers.push(courierRow);
    newMarkers.push(userRow);

    // if we have branch map pin , inject it
    if (props.branch) {
      let branchRow = {
        photo: BranchPinIcon,
        lat: props["branch"].lat,
        lng: props["branch"].lng,
      };

      newMarkers.push(branchRow);
    }

    // set all markers
    setMarkers(newMarkers);
  }, []);

  useEffect(() => {
    setMarkers((prev) => [
      {
        photo: CourierPinIcon,
        lat: props["courier"].lat,
        lng: props["courier"].lng,
      },
      { ...prev[1] }
    ]);
  }, [props]);

  return markers.length > 0 ? (
    <GoogleMap
      defaultZoom={14}
      defaultCenter={{ lat: markers[0].lat, lng: markers[0].lng }}
    >
      {console.log(markers)}
      {markers.map((marker: any, i: number) => (
        <Marker
          key={i}
          icon={marker.photo}
          position={{ lat: marker.lat, lng: marker.lng }}
        />
      ))}
    </GoogleMap>
  ) : (
    <></>
  );
});
